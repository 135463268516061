import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const MapContainer = ({ long, lat }) => {  

  const [isLoaded, setIsLoaded] = useState(false);
  const center = { lat: lat, lng: long }; 

  const mapContainerStyle = {
    width: '100%',
    height: '300px',
  };

  const onLoad = () => {
    setIsLoaded(true);
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyBCX6h3k0girfVPKK_JWPqBog7dBsg56uo" onLoad={onLoad}>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={15}
        >
          <Marker position={center} />
        </GoogleMap>
      )}
    </LoadScript>
  );
};

export default MapContainer;
