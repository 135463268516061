import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSupportTickets } from "./redux/actions/supportActions";
import moment from "moment";
import { Button, Dropdown, DropdownButton, Tooltip } from "react-bootstrap";
import CommonModal from "./common/CommonModal";
import SupportModal from "./elements/SupportModal";
import ReactPaginate from "react-paginate";
import { Helmet } from "react-helmet";
import { useGetHelpAndSupportQuery } from "../services/CategoryApi"

const Support = () => {
  const limit = 10;
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState("Client");
  const [paginated, setPaginated] = useState([]);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [showSupport, setShowSupport] = useState(false);
  const [supportId, setSupportId] = useState();
  const [modalData, setModalData] = useState();
  const [helpAndSupportList, setHelpAndSupportList] = useState([])
  const [filteredList, setFilteredList] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [status, setStatus] = useState()
  const { data: helpSupport, refetch } = useGetHelpAndSupportQuery({ role: currentTab })
  console.log('no of support tickets', helpSupport?.data?.length)

  const { data, role, pagination } = useSelector((state) => state.support);

  const tabs = [{ label: "Client" }, { label: "Referee" }, { label: "Leads" }];
  const statusObj = {
    Pending: { label: "Pending", variant: "primary" },
    Processing: { label: "Processing", variant: "warning" },
    Closed: { label: "Closed", variant: "success" },
  };
  // useEffect(() => {
  //   dispatch(getSupportTickets({ role: currentTab, page, limit }));
  // }, [currentTab, page]);

  const handleChangeStatus = (e, id) => {
    setShow(true);
    setModalData({ id, status: e });
  };
  const handlePageClick = (e) => {
    setPage(e.selected);
    const newOffset = e.selected * itemsPerPage;
    setOffset(newOffset);
  };

  const applyFilters = () => {
    console.log('Applying filters');
    
    let filtered = helpAndSupportList; // Use the main list, not paginated
  
    if (searchTerm.length > 0) {
      const lowerSearch = searchTerm.toLowerCase();
      filtered = filtered.filter((item) =>
        item?.subject?.toLowerCase().includes(lowerSearch) ||
        item?.description?.toLowerCase().includes(lowerSearch) ||
        item?.user_id?.name?.toLowerCase().includes(lowerSearch)
      );
    }
  
    if (status) {
      filtered = filtered.filter((item) => item?.status === status);
    }
  
    setFilteredList(filtered); 
    setPageCount(Math.ceil(filtered.length / itemsPerPage)); 
    setPaginated(filtered.slice(offset, offset + itemsPerPage)); 
  };

  const clearFilters = () => {
    setSearchTerm("");
    setStatus(null);
    setOffset(0);
    setPage(0);
    applyFilters(); 
  };
  
  

  useEffect(() => {
  applyFilters()
  }, [paginated, searchTerm, status])

  const paginationHandler = () => {
    const endOffset = offset + itemsPerPage;
    let paginated = [];
    if (helpAndSupportList.length > 0) {
      setPageCount(Math.ceil(helpAndSupportList.length / itemsPerPage));
      paginated = helpAndSupportList.slice(offset, endOffset);
    }
    // if (search.length > 0) {
    //   let searchArr = transactions.filter((item) =>
    //     item.sender?.name.toLowerCase().includes(search.toLowerCase()) || item.notary?.name.toLowerCase().includes(search.toLowerCase())
    //   );
    //   setPageCount(Math.ceil(searchArr.length / itemsPerPage));
    //   paginated = searchArr.slice(offset, endOffset);
    // } else {
    //   setPageCount(Math.ceil(transactions.length / itemsPerPage));
    //   paginated = transactions.slice(offset, endOffset);
    // }
    setPaginated(paginated);
  };

  useEffect(() => {
    console.log('no of support tickets coming in paginated', paginated?.length)
  }, [paginated])

  useEffect(() => {
    if (helpSupport?.status) {
      let supportData = helpSupport?.data
      setHelpAndSupportList(supportData)
      setPageCount(supportData / itemsPerPage)
     console.log('supportData--->',supportData)

    }
  }, [helpSupport])

  useEffect(() => {
    paginationHandler();
  }, [helpAndSupportList, offset]);


  useEffect(() => {
    setOffset(0);
    setPage(0);
  }, [currentTab])

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>Support</h3>
      </header>

      <Helmet>
        <title>Support</title>
      </Helmet>

      {modalData && (
        <CommonModal
          show={show}
          setShow={setShow}
          type="changeSupportStatus"
          data={modalData}
          setPaginated={setPaginated}
        />
      )}

      {supportId && (
        <SupportModal
          show={showSupport}
          setShow={setShowSupport}
          supportId={supportId}
        />
      )}

      <div className="card">
        <div className="card-body support-card">
          <div className="container text-center">
          <div className="row mb-3 align-items-center">
              {/* Search Bar */}
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by subject , decription , name ...."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              {/* Filters - Role & Status */}
              <div className="col-md-8 d-flex justify-content-end">
                {/* Dropdown for Role Selection (Tabs Functionality) */}
                <div className="me-3">
                  <DropdownButton
                    variant="primary"
                    title={currentTab}
                    onSelect={(selectedTab) => setCurrentTab(selectedTab)}
                  >
                    {tabs.map((tab) => (
                      <Dropdown.Item key={tab.label} eventKey={tab.label}>
                        {tab.label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>

                </div>

                {/* Dropdown for Status Filter */}
                <div>
                  <DropdownButton
                    variant="secondary"
                    title="Filter by Status"
                    onSelect={(selectedStatus) => console.log(selectedStatus)} // Add filtering logic later
                  >
                    {Object.keys(statusObj).map((statusKey) => (
                      <Dropdown.Item key={statusKey} eventKey={statusKey} onClick={() => setStatus(statusKey)}>
                        {statusObj[statusKey].label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </div>
                <Button 
                  variant={searchTerm || status ? "warning" : "outline-primary"} 
                  onClick={clearFilters}
                  className="me-2 ms-3"
                >
                  Clear Filters
                </Button>
              </div>
            </div>

          </div>
          <div className="card-body support-table">
            <div className="container">
              <div className="row">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Description</th>
                      <th scope="col">Date Created</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody class="table-group-divider">
                    {paginated && paginated?.map((supportObj, i) => {
                      return (
                        <tr>
                          <th scope="row">{i + 1}</th>
                          <td>{supportObj?.user_id?.name}</td>
                          <td>{supportObj?.subject.length > 12 ? supportObj?.subject.substring(0, 15) + "..." : supportObj?.subject}</td>
                          <td>                   
                            <div className="div-ellipsis">
                              {supportObj?.description}
                            </div>
                          </td>
                          <td>
                            {supportObj.createdAt.split('T')[0]}
                          </td>
                          <td>
                            <DropdownButton
                              id="dropdown-basic-button"
                              title={statusObj[supportObj?.status]?.label}
                              variant={statusObj[supportObj?.status]?.variant}
                              onSelect={(e) =>
                                handleChangeStatus(e, supportObj?._id)
                              }
                            >
                              {Object.keys(statusObj)
                                ?.filter((key) => key !== supportObj?.status)
                                ?.map((key) => {
                                  return (
                                    <Dropdown.Item eventKey={key}>
                                      {statusObj[key]?.label}
                                    </Dropdown.Item>
                                  );
                                })}
                            </DropdownButton>
                          </td>
                          <td>
                            <button
                              onClick={() => {
                                setSupportId(supportObj?._id);
                                setShowSupport(true);
                              }}
                              className="btn support-edit-btn"
                            >
                              <i class="bx bxs-edit"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="pagination-div">
                {helpAndSupportList && helpAndSupportList.length > 10 && (
                  <ReactPaginate
                    breakLabel="..."
                    breakClassName={"break"}
                    nextLabel="next >"
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    onPageChange={handlePageClick}
                    forcePage={page}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< previous"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
